import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import { Login } from './pages/login/Login';
import { Clients } from './pages/clients/Clients';
import { Client } from './pages/clients/Client';
import { Providers } from './pages/providers/Providers';
import { Provider } from './pages/providers/Provider';
import { Orders } from './pages/orders/Orders';
import { HelpCenter } from './pages/helpCenter/HelpCenter';
import { RespiteCare } from './pages/respiteCare/RespiteCare';
import { Option } from './pages/respiteCare/Option';
import { ServiceCities } from './pages/settings/ServiceAreas/ServiceCities';
import { Releases } from './pages/settings/Releases/Releases';
import { ContentManagerHelpCenter } from './pages/contentManager/HelpCenter/HelpCenter';
import { ContentManagerFAQ } from './pages/contentManager/FAQ/FAQ';
import { Admins } from './pages/admins/Admins';
import { isUserRoleMarketing, isUserSuperAdmin } from './utils/helpers';
import { MainContext } from './App';
import { Services} from './pages/contentManager/Services/Services';
import { Campaings } from './pages/campaings/Campaings';
import { Translations } from './pages/contentManager/translations/Translations';
import { CampaignDetails } from './pages/campaings/CampaignDetails';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Tracking } from './pages/tracking/Tracking';
import { Billboard } from './pages/contentManager/billboard/Billboard';
import './App.css';

export const AppRouter: React.FC = () => {
    const { userAuthData } = useContext(MainContext);

    return (
        <Router>
            <Switch>
            <Route path="/login" component={Login}></Route>
            <AuthenticatedRoute exact path="/dashboard">
                <Dashboard />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/clients/:currentPage">
                <Clients />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/clients/:id/:currentPage">
                <Client />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/providers/:configIndex/:currentPage">
                <Providers />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/providers/:id/:configIndex/:currentPage">
                <Provider />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/orders/active">
                <Orders key="active" configs={[
                {
                    label: 'In transit',
                    params: {
                    statuses: 'IN_TRANSIT',
                    removeIndex: [7, 8]
                    }
                },
                {
                    label: 'In progress',
                    params: {
                    statuses: 'IN_PROGRESS',
                    removeIndex: [7, 8]
                    },
                },
                ]} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/orders/pending">
                <Orders key="pending" configs={[
                {
                    label: 'Pending For Now',
                    params: {
                    statuses: 'PENDING',
                    removeIndex: [7, 8]
                    },
                },
                {
                    label: 'Cancelled By Client',
                    params: {
                    statuses: 'CANCELLED_BY_CLIENT',
                    preCancelledStatus: 'PENDING',
                    removeIndex: [7, 8]
                    },
                },
                {
                    label: 'Provider Not Found',
                    params: {
                    statuses: 'PROVIDER_NOT_FOUND',
                    removeIndex: [7, 8],
                    },
                },
                ]} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/orders/complete">
                <Orders key="complete" configs={[
                {
                    label: 'Finisihed',
                    params: {
                    statuses: 'FINISHED',
                    removeIndex: [7, 8]
                    },
                },
                ]} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/orders/cancelled">
                <Orders key="cancelled" configs={[
                {
                    label: 'Cancelled by client',
                    params: {
                    statuses: 'CANCELLED_BY_CLIENT',
                    preCancelledStatus: 'IN_TRANSIT',
                    removeIndex: [6]
                    },
                },
                {
                    label: 'Cancelled by provider',
                    params: {
                    statuses: 'CANCELLED_BY_PROVIDER',
                    preCancelledStatus: 'IN_TRANSIT',
                    removeIndex: [6, 8]
                    },
                },
                {
                    label: 'Cancelled by FLEXXI',
                    params: {
                        statuses: 'AUTO_CANCELLED',
                        removeIndex: [6, 8]
                    },
                },

                ]} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/orders/all">
                <Orders key="all" configs={[
                {
                    label: 'All',
                    params: {
                        removeIndex: [6]
                    },
                }
                ]} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/respiteCare/:configIndex/:currentPage">
                <RespiteCare />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/respiteCare/:id/:configIndex/:currentPage">
                <Option />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/helpCenter">
                <HelpCenter configs={[
                {
                    label: 'Client Tickets',
                    params: {
                    submittedByClient: true,
                    },
                },
                {
                    label: 'Provider Tickets',
                    params: {
                    submittedByProvider: true,
                    },
                },
                ]} />
            </AuthenticatedRoute>
            {!isUserRoleMarketing(userAuthData) &&
                <AuthenticatedRoute exact path="/settings/serviceCities">
                   <ServiceCities />
                </AuthenticatedRoute>
            }
            {isUserSuperAdmin(userAuthData) && <AuthenticatedRoute exact path="/settings/releases">
                <Releases />
            </AuthenticatedRoute>}
            <AuthenticatedRoute exact path="/contentManager/helpCenter">
                <ContentManagerHelpCenter configs={[
                {
                    label: 'Client Help Center',
                    params: {
                    type: 'CLIENT'
                    },
                },
                {
                    label: 'Provider Help Center',
                    params: {
                    type: 'PROVIDER'
                    },
                },
                ]} />
            </AuthenticatedRoute> 
            <AuthenticatedRoute exact path="/contentManager/faq">
                <ContentManagerFAQ configs={[
                {
                    label: 'Client FAQ',
                    params: {
                    type: 'CLIENT'
                    },
                },
                {
                    label: 'Provider FAQ',
                    params: {
                    type: 'PROVIDER'
                    },
                },
                ]} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/contentManager/services" >
                <Services />
            </AuthenticatedRoute>
            {!isUserRoleMarketing(userAuthData) && <AuthenticatedRoute exact path="/contentManager/translations" >
                <Translations configs={[
                {
                    label: 'Client\'s app',
                    params: {
                        appType: 'CLIENT'
                    },
                },
                {
                    label: 'Provider\'s app',
                    params: {
                        appType: 'PROVIDER'
                    },
                },
                ]}
                />
            </AuthenticatedRoute>}
            {!isUserRoleMarketing(userAuthData) && <AuthenticatedRoute exact path="/contentManager/billboard" >
                <Billboard />
            </AuthenticatedRoute>}
            <AuthenticatedRoute exact path="/campaigns/:configIndex">
                <Campaings />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/campaigns/:id/:configIndex">
                <CampaignDetails />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/tracking">
                <Tracking />
            </AuthenticatedRoute>
            {isUserSuperAdmin(userAuthData) && <AuthenticatedRoute exact path="/admins">
                <Admins />
            </AuthenticatedRoute>}  
            <Redirect to={{
                pathname: "/dashboard",
            }} />
            </Switch>
        </Router>
    )
};
